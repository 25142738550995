import type { ConversationStarterResource, PodcasterResource } from '@/types'
import { defineStore } from 'pinia'
import type { ComputedRef, Ref } from 'vue'
import { computed, ref, watch } from 'vue'
import { router } from '@inertiajs/vue3'
import useApi from '@/Composables/useApi'
import { ScrollState } from '@/Enum/ScrollState'

const { getJson } = useApi()

export const definePodcasterStore = async (selectedPodcasters?: Array<string> | undefined) => {
    const store = usePodcasterStore()

    await store.fetchAllPodcasters()

    if (selectedPodcasters) {
        store.selectPodcasters(selectedPodcasters)
    }

    return store
}

export const usePodcasterStore = defineStore('podcaster', () => {
    const open: Ref<Boolean> = ref(false)
    const podcasters: Ref<PodcasterResource[]> = ref<PodcasterResource[]>([])
    const selectedPodcastersIds: Ref<string[]> = ref<string[]>([])
    const yearRange: Ref<{ from: number; till: number }> = ref({ from: new Date().getFullYear(), till: new Date().getFullYear() })
    const scrollState: Ref<ScrollState> = ref<ScrollState>(ScrollState.Idle)
    const currentPage = ref(0)

    const fetchAllPodcasters = async () => {
        if (podcasters.value.length > 0) {
            return
        }

        const initialPodcasters = await getJson('/api/podcasters')

        podcasters.value = initialPodcasters
        selectedPodcastersIds.value = initialPodcasters.map((p: PodcasterResource) => p.id)
    }

    async function loadMorePodcasters() {
        if (scrollState.value === ScrollState.Idle) {
            await fetchPodcasters()
        }
    }

    const fetchPodcasters = async () => {
        try {
            scrollState.value = ScrollState.Requesting
            currentPage.value += 1
            const response = await getJson(`/api/podcasters?page=${currentPage.value}`)
            podcasters.value = [...podcasters.value, ...response.data]

            if (response.current_page >= response.last_page) {
                scrollState.value = ScrollState.Ended
            } else {
                scrollState.value = ScrollState.Idle
            }
        } catch (e: any) {
            scrollState.value = ScrollState.Failed
        }
    }

    const selectedPodcasters = computed(() => {
        return podcasters.value.filter((p) => selectedPodcastersIds.value.includes(p.id))
    })

    const selectPodcasters = (podcasters: string[]) => {
        selectedPodcastersIds.value = podcasters
    }

    const trainingDate: ComputedRef<Date> = computed((): Date => new Date(selectedPodcasters.value[0]?.latest_podcast_date))

    watch(selectedPodcastersIds, () => {
        // sort array in ascending order based on oldest_podcast_date
        const sortedOldestPodcasters = selectedPodcasters.value.sort(
            (a, b) => new Date(a.oldest_podcast_date || 'now').getFullYear() - new Date(b.oldest_podcast_date).getFullYear()
        )

        const sortedLatestPodcasters = selectedPodcasters.value.sort(
            (a, b) => new Date(a.oldest_podcast_date || 'now').getFullYear() - new Date(b.oldest_podcast_date).getFullYear()
        )

        const from = new Date(sortedOldestPodcasters[0]?.oldest_podcast_date || 'now').getFullYear()
        const till = new Date(sortedLatestPodcasters[0]?.latest_podcast_date || 'now').getFullYear()

        yearRange.value = {
            from,
            till,
        }
    })

    const conversationStarters: ComputedRef<ConversationStarterResource[]> = computed(() => {
        return selectedPodcasters.value[0]?.conversation_starters || []
    })

    // TODO
    const navigateToPodcaster = (podcaster: PodcasterResource) => {
        router.visit(route('clipfinder.podcasters.show', [podcaster.slug]), {
            only: ['podcaster', 'conversation', 'messages'],
            preserveScroll: true,
        })
    }

    function toggle() {
        open.value = !open.value
    }

    function close() {
        open.value = false
    }

    const isWaiting = computed(() => scrollState.value === ScrollState.Requesting)
    const failed = computed(() => scrollState.value === ScrollState.Failed)

    return {
        fetchAllPodcasters,
        toggle,
        close,
        open,
        yearRange,
        podcasters,
        selectedPodcasters,
        selectedPodcastersIds,
        selectPodcasters,
        navigateToPodcaster,
        trainingDate,
        conversationStarters,
        isWaiting,
        failed,
        loadMorePodcasters,
    }
})
