import type { CategoryResource } from '@/types'
import { defineStore } from 'pinia'
import type { Ref } from 'vue'
import { computed, ref } from 'vue'
import useApi from '@/Composables/useApi'

const { getJson } = useApi()

export const defineCategoryStore = async (selectedCategories?: Array<string> | undefined) => {
    const store = useCategoryStore()
    await store.fetchAllCategories()
    return store
}

export const useCategoryStore = defineStore('category', () => {
    const open: Ref<Boolean> = ref(false)
    const categories: Ref<CategoryResource[]> = ref<CategoryResource[]>([])
    const selectedCategoryIds: Ref<string[]> = ref<string[]>([])

    const fetchAllCategories = async () => {
        if (categories.value.length > 0) {
            return
        }
        const initialCategories = await getJson('/api/categories')
        categories.value = initialCategories
        selectedCategoryIds.value = initialCategories.map((p: CategoryResource) => p.id)
    }

    const fetchPodcasterCategories = async (podcasterId: string) => {
        const podcasterCategories = await getJson(`/api/podcaster/${podcasterId}/categories`)
        categories.value = podcasterCategories
    }

    const fetchPodcasterClipCategories = async (podcasterId: string) => {
        const podcasterCategories = await getJson(`/api/podcaster/${podcasterId}/clip-categories`)
        categories.value = podcasterCategories
    }

    const selectedCategories = computed(() => {
        return categories.value.filter((p) => selectedCategoryIds.value.includes(p.id))
    })

    const parentCategories = computed(() => {
        return categories.value.filter((p) => p.parent_id === null)
    })

    function toggle() {
        open.value = !open.value
    }

    function close() {
        open.value = false
    }

    return {
        fetchAllCategories,
        fetchPodcasterCategories,
        fetchPodcasterClipCategories,
        toggle,
        close,
        open,
        categories,
        selectedCategories,
        parentCategories,
    }
})
