import { defineStore } from 'pinia'
import type { Ref } from 'vue'
import { ref, watch } from 'vue'

export const usePlayerStore = defineStore('player', () => {
    const playbackRate: Ref<number> = ref(parseFloat(localStorage.getItem('player.playbackRate') || '1'))

    watch(playbackRate, () => {
        localStorage.setItem('player.playbackRate', `${playbackRate.value}`)
    })

    return { playbackRate }
})
