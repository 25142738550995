import { defineStore } from 'pinia'
import type { Ref } from 'vue'
import { computed, ref } from 'vue'
import useApi from '@/Composables/useApi'
import type { CategoryResource, PodcasterResource, PodcastResource } from '@/types'
import { ScrollState } from '@/Enum/ScrollState'

const { getJson } = useApi()

export const definePodcastStore = (
    podcaster: PodcasterResource | undefined = undefined,
    category: CategoryResource | undefined = undefined
): ReturnType<typeof usePodcastStore> => {
    const store = usePodcastStore()
    store.setPodcaster(podcaster)
    store.setCategory(category)
    store.fetchPodcasts()
    return store
}

export const usePodcastStore = defineStore('podcast', () => {
    const podcasts: Ref<PodcastResource[]> = ref<PodcastResource[]>([])
    const podcaster: Ref<PodcasterResource | undefined> = ref<PodcasterResource>()
    const category: Ref<CategoryResource | undefined> = ref<CategoryResource>()
    const currentPage = ref(0)
    const scrollState: Ref<ScrollState> = ref<ScrollState>(ScrollState.Idle)

    const fetchPodcasts = async () => {
        try {
            scrollState.value = ScrollState.Requesting
            currentPage.value += 1
            const response = await getJson(`/api/${podcaster.value.id}/podcasts?page=${currentPage.value}`)
            podcasts.value = [...podcasts.value, ...response.data]

            if (response.current_page >= response.last_page) {
                scrollState.value = ScrollState.Ended
            } else {
                scrollState.value = ScrollState.Idle
            }
        } catch (e: any) {
            scrollState.value = ScrollState.Failed
        }
    }

    async function loadMorePodcasts() {
        if (scrollState.value === ScrollState.Idle) {
            await fetchPodcasts()
        }
    }

    // TODO
    const fetchCategoryPodcasts = async () => {
        const url = `/api/${podcaster.value.id}/podcasts/categories`
        category.value = await getJson(url)
    }

    const setPodcaster = (newPodcaster: PodcasterResource | undefined) => {
        podcaster.value = newPodcaster
    }

    const setCategory = (newCategory: CategoryResource | undefined) => {
        category.value = newCategory
    }

    const isWaiting = computed(() => scrollState.value === ScrollState.Requesting)
    const failed = computed(() => scrollState.value === ScrollState.Failed)

    return {
        podcasts,
        fetchPodcasts,
        setPodcaster,
        setCategory,
        loadMorePodcasts,
        isWaiting,
        failed,
    }
})
