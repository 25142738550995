import { router } from '@inertiajs/vue3'

export function inertiaRoutingInstrumentation(customStartTransaction, startTransactionOnPageLoad = true, startTransactionOnLocationChange = true) {
    let activeTransaction
    let name
    if (startTransactionOnPageLoad && window && window.location) {
        name = window.location.pathname

        activeTransaction = customStartTransaction({
            name,
            op: 'pageload',
            metadata: {
                source: 'route',
            },
        })
    }

    if (startTransactionOnLocationChange && window && window.location) {
        router.on('before', (_to, _from) => {
            if (activeTransaction) {
                activeTransaction.finish()
            }

            const newName = window.location.pathname

            if (newName !== name) {
                activeTransaction = customStartTransaction({
                    name: newName,
                    op: 'navigation',
                    metadata: {
                        source: 'route',
                    },
                })
            }
        })

        if (window && window.location) {
            router.on('finish', () => {
                activeTransaction.setName(window.location.pathname, 'route')
            })
        }
    }
}
