import useApi from '@/Composables/useApi'
import type { LLMModelResource, UserResource } from '@/types'
import { defineStore } from 'pinia'
import type { Ref } from 'vue'
import { computed, ref } from 'vue'
import { usePage } from '@inertiajs/vue3'

const { patchJson } = useApi()

export const defineUserStore = () => {
    const store = useUserStore()

    const page = usePage()
    store.init(page.props.user as UserResource | null)
    return store
}

export const useUserStore = defineStore('user', () => {
    const user: Ref<UserResource | null> = ref<UserResource | null>(null)

    const init = (initialUser: UserResource | null) => {
        user.value = initialUser
    }

    async function setUserModel(model: LLMModelResource) {
        if (user.value) {
            user.value.llm_model = model
        }

        await patchJson('/api/user', {
            llm_model: model.id,
        })
    }

    const loggedIn = computed(() => user.value !== null)

    return { init, user, setUserModel, loggedIn }
})
