import Echo from 'laravel-echo'
import Pusher from 'pusher-js'
import type { App } from 'vue'

declare global {
    interface Window {
        Pusher: Pusher
        Echo: Echo
    }
}

export const LaravelEcho = {
    install(app: App) {
        /* @ts-ignore */
        window.Pusher = Pusher
        window.Echo = new Echo({
            broadcaster: 'reverb',
            key: import.meta.env.VITE_REVERB_APP_KEY,
            wsHost: import.meta.env.VITE_REVERB_HOST,
            wsPort: import.meta.env.VITE_REVERB_PORT,
            wssPort: import.meta.env.VITE_REVERB_PORT,
            forceTLS: (import.meta.env.VITE_REVERB_SCHEME ?? 'https') === 'https',
            encrypted: true,
            disableStats: true,
            enableLogging: true,
            enabledTransports: ['ws', 'wss'],
            cluster: 'default',
            authEndpoint: import.meta.env.VITE_REVERB_ENDPOINT,
            auth: {
                headers: {
                    Accept: 'application/json',
                },
            },
        })

        app.provide('echo', window.Echo)
    },
}
