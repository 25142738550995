import { defineStore } from 'pinia'
import type { Ref } from 'vue'
import { ref } from 'vue'
import { useBreakpoints, breakpointsTailwind } from '@vueuse/core'

// https://vueuse.org/core/useBreakpoints/
export const useMenuStore = defineStore('menu', () => {
    const breakpoints = useBreakpoints(breakpointsTailwind)

    const open: Ref<Boolean> = ref(breakpoints.greaterOrEqual('md'))
    const hover: Ref<Boolean> = ref(false)
    const mobile = ref(breakpoints.smaller('md'))

    function toggle() {
        open.value = !open.value
    }

    function close() {
        open.value = false
    }

    function onHover(state: Boolean) {
        hover.value = state
    }

    return { open, hover, mobile, onHover, close, toggle }
})
